// SemanticUI-free pre-@plone/components
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@plone/components';
import MobileNavigation from '@kitconcept/volto-light-theme/components/MobileNavigation/MobileNavigation';
import { setSidebarTab } from '@plone/volto/actions/sidebar/sidebar';
import { FormattedMessage } from 'react-intl';
import { HashLink as Link } from 'react-router-hash-link';
import isEmpty from 'lodash/isEmpty';

// import { Link } from 'react-router-dom';
// import { FormattedMessage } from 'react-intl';
import config from '@plone/volto/registry';

import {
  // Anontools,
  LanguageSelector,
  Logo,
  Navigation,
  // SearchWidget,
} from '@plone/volto/components';

const Header = (props) => {
  const { pathname } = props;
  // const token = useSelector((state) => state.userSession.token);
  const content = useSelector((state) => state.content.data);
  const formState = useSelector((state) => state.form.global);
  const dispatch = useDispatch();
  const backgroundColor =
    formState?.background_color || content?.background_color;

  const defaultColorStyle = config.blocks.themes.find(
    (color) => color.name === config.settings.defaultBackgroundColor,
  )?.style;
  const style =
    typeof backgroundColor === 'object' && !isEmpty(backgroundColor)
      ? backgroundColor
      : defaultColorStyle;

  const onHandleClick = () => {
    dispatch(setSidebarTab(0));
  };

  return (
    <header
      role="presentation"
      className="header-wrapper"
      style={style}
      onClick={formState ? onHandleClick : null}
    >
      <Container>
        <div className="header">
          <div className="logo-nav-wrapper">
            <div className="logo">
              <Logo />
            </div>
            <Navigation pathname={pathname} />
            <MobileNavigation pathname={pathname} />

            <div className="search-wrapper navigation-desktop">
              <Link className="contact-link" smooth={true} to="#contact-form">
                <FormattedMessage id="Contact" defaultMessage="Contact" />
              </Link>
              <LanguageSelector />
            </div>
          </div>
          {/* <div className="tools-wrapper">
            <div className="tools">{!token && <Anontools />}</div>
          </div> */}
        </div>
      </Container>
    </header>
  );
};

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

Header.defaultProps = {
  token: null,
};

export default Header;
