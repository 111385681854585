import React, { useEffect } from 'react';
import { Container } from '@plone/components';
import { FormattedMessage } from 'react-intl';
import { defineMessages, useIntl } from 'react-intl';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import { Icon, Toast } from '@plone/volto/components';
import { toast } from 'react-toastify';
import { usePrevious } from '@plone/volto/helpers';
import { emailNotification } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { QuantaTextField, QuantaTextAreaField } from '@plone/components';
import { NavLink } from 'react-router-dom';

const messages = defineMessages({
  Name: {
    id: 'Your Name',
    defaultMessage: 'Your Name',
  },
  Email: {
    id: 'Your Email',
    defaultMessage: 'Your Email',
  },
  Message: {
    id: 'Your Message',
    defaultMessage: 'Your Message',
  },
  Send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  Information: {
    id: 'We look forward to getting to know you and your project.',
    defaultMessage: 'We look forward to getting to know you and your project.',
  },
  MessageSent: {
    id: 'Your message has been sent, we will get in touch with you shortly.',
    defaultMessage:
      'Your message has been sent, we will get in touch with you shortly.',
  },
  Success: {
    id: 'Message sent',
    defaultMessage: 'Message sent',
  },
  Contact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  Address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  Wrong: {
    id: 'Something went wrong during form submission.',
    defaultMessage: 'Something went wrong during form submission.',
  },
  PrivacyPolicy: { id: 'Privacy Policy', defaultMessage: 'Privacy Policy' },
  Imprint: { id: 'Imprint', defaultMessage: 'Imprint' },
});

const useEmailNotification = () => {
  const loading = useSelector((state) => state.emailNotification.loading);
  const loaded = useSelector((state) => state.emailNotification.loaded);
  const error = useSelector((state) => state.emailNotification.error);

  return { loading, loaded, error };
};

const initialState = {
  Name: '',
  Email: '',
  Message: '',
};

function isValidEmail(email) {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
}

const Footer = () => {
  const [state, setState] = React.useState(initialState);
  const [invalidEmail, setInValidEmail] = React.useState(false);
  const [invalidMessage, setInvalidMessage] = React.useState(false);
  const [invalidName, setInvalidName] = React.useState(false);
  const lang = useSelector((state) => state.intl.locale);

  const intl = useIntl();
  const dispatch = useDispatch();
  const { loaded, loading, error } = useEmailNotification();
  const prevloading = usePrevious(loading);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (prevloading && loaded) {
      setState(initialState);
      toast.success(
        <Toast
          success
          title={intl.formatMessage(messages.Success)}
          content={intl.formatMessage(messages.MessageSent)}
        />,
      );
    }
  }, [intl, loaded, prevloading]);

  const onChangeHandler = (name, value) => {
    if (name === 'Email') {
      setInValidEmail(!isValidEmail(value));
    } else if (name === 'Message') {
      setInvalidMessage(value.length === 0);
    } else if (name === 'Name') {
      setInvalidName(value.length === 0);
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (!invalidMessage && !invalidName && !invalidEmail) {
      // Check if message input is not invalid
      dispatch(
        emailNotification(
          state.Email,
          state.Message,
          state.Name,
          'New lead from website.',
        ),
      );
    }
  };

  return (
    <footer id="footer">
      <Container>
        <div className="footer">
          <div id="contact-form" className="footer-form">
            <h2 className="contact">{intl.formatMessage(messages.Contact)}</h2>
            <p className="extra-information">
              {intl.formatMessage(messages.Information)}
            </p>
            {error && (
              <p className="extra-information">{`${intl.formatMessage(
                messages.Wrong,
              )} ${error.message}`}</p>
            )}
            <form onSubmit={onSubmitHandler}>
              <div className="first-row">
                <div className="custom-input-group">
                  <QuantaTextField
                    name="Name"
                    label={intl.formatMessage(messages.Name)}
                    placeholder=""
                    onChange={(value) => onChangeHandler('Name', value)}
                    value={state.Name}
                    isInvalid={invalidName}
                    isRequired={true}
                    type="text"
                    maxLength="50"
                  />
                </div>
                <div className="custom-input-group">
                  <QuantaTextField
                    name="Email"
                    label={intl.formatMessage(messages.Email)}
                    placeholder=""
                    onChange={(value) => onChangeHandler('Email', value)}
                    value={state.Email}
                    isInvalid={invalidEmail}
                    isRequired={true}
                    type="email"
                    maxLength="60"
                  />
                </div>
              </div>
              <div className="custom-input-group">
                <QuantaTextAreaField
                  name="Message"
                  label={intl.formatMessage(messages.Message)}
                  placeholder=""
                  onChange={(value) => onChangeHandler('Message', value)}
                  value={state.Message}
                  isInvalid={invalidMessage}
                  isRequired={true}
                  type="text"
                />
              </div>
              <div className="form-button">
                <button
                  name="submit"
                  type="submit"
                  title={intl.formatMessage(messages.Send)}
                  aria-label={intl.formatMessage(messages.Send)}
                >
                  <Icon name={aheadSVG} size="20px" />
                  <span>{intl.formatMessage(messages.Send)}</span>
                </button>
              </div>
            </form>
          </div>
          <div className="content-container">
            <div className="address-contact-container">
              <div className="address">
                <p className="headline">
                  {intl.formatMessage(messages.Address)}
                </p>
                <p className="details">
                  <span>kitconcept GmbH</span>
                  <span>Riesstraße 21</span>
                  <span>53113 Bonn</span>
                </p>
              </div>
              <div className="contact">
                <p className="headline">
                  {intl.formatMessage(messages.Contact)}
                </p>
                <p className="details">
                  <span>
                    <FormattedMessage id="phone" defaultMessage="phone" /> +49
                    228-286 288 380{' '}
                  </span>
                  <span>
                    <FormattedMessage id="fax" defaultMessage="fax" /> +49
                    228-286 288 389
                  </span>
                  <span>info@kitconcept.com</span>
                </p>
              </div>
            </div>
            <div className="links-container">
              <ul className="list-item">
                <li>
                  <NavLink
                    to={lang === 'de' ? '/de/datenschutz' : '/en/privacy'}
                    aria-label={intl.formatMessage(messages.PrivacyPolicy)}
                  >
                    <FormattedMessage
                      id="Privacy Policy"
                      defaultMessage="Privacy Policy"
                    />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={lang === 'de' ? '/de/impressum' : '/en/impress'}
                    aria-label={intl.formatMessage(messages.Imprint)}
                  >
                    <FormattedMessage id="Imprint" defaultMessage="Imprint" />
                  </NavLink>
                </li>
              </ul>
              <p className="copyright-year">© kitconcept {currentYear}</p>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
