import React from 'react';
import { useIntl } from 'react-intl';
import EditBlock from '@plone/volto/components/manage/Blocks/Block/Edit';
import {
  getBlocks,
  getBlocksFieldname,
  applyBlockDefaults,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import {
  addBlock,
  insertBlock,
  changeBlock,
  deleteBlock,
  moveBlock,
  mutateBlock,
  nextBlockId,
  previousBlockId,
} from '@plone/volto/helpers';
import EditBlockWrapper from './EditBlockWrapper';
import { setSidebarTab } from '@plone/volto/actions';
import { useDispatch } from 'react-redux';
import { useDetectClickOutside, useEvent } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import cx from 'classnames';
import {
  DndContext,
  useDroppable,
  PointerSensor,
  useSensors,
  useSensor,
} from '@dnd-kit/core';

// Fix internal pointer sensors by adding a custom one that bails off given a exclusion list
// https://github.com/clauderic/dnd-kit/issues/477
class GridCellPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent: event }) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target)
        ) {
          return false;
        }
        // if (!isInteractiveElement(event.target)) {
        //   console.log(event.target);
        // }
        return true;
      },
    },
  ];
}

function isInteractiveElement(element) {
  const interactiveElements = [
    'button',
    'input',
    'textarea',
    'select',
    'option',
    'svg',
    'path',
  ];

  if (interactiveElements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  return false;
}

const DroppableWrapper = ({ childId, blockProps }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: childId,
  });
  const style = {
    borderColor: isOver ? '1px solid green' : undefined,
  };

  return (
    <div ref={setNodeRef} style={style} className="here">
      <EditBlock key={childId} {...blockProps} />
    </div>
  );
};

const BlocksForm = (props) => {
  const {
    pathname,
    onChangeField,
    properties,
    type,
    navRoot,
    onChangeFormData,
    selectedBlock,
    multiSelected,
    onSelectBlock,
    allowedBlocks,
    showRestricted,
    title,
    description,
    metadata,
    manage,
    // children,
    isMainForm = true,
    isContainer,
    stopPropagation,
    disableAddBlockOnEnterKey,
    blocksConfig = config.blocks.blocksConfig,
    editable = true,
    // direction = 'vertical',
    history,
    location,
    token,
    variation,
  } = props;

  const blockList = getBlocks(properties);

  const dispatch = useDispatch();
  const intl = useIntl();

  const ClickOutsideListener = () => {
    onSelectBlock(null);
    dispatch(setSidebarTab(0));
  };

  const ref = useDetectClickOutside({
    onTriggered: ClickOutsideListener,
    triggerKeys: ['Escape'],
    // Disabled feature for now https://github.com/plone/volto/pull/2389#issuecomment-830027413
    disableClick: true,
    disableKeys: !isMainForm,
  });

  const handleKeyDown = (
    e,
    index,
    block,
    node,
    {
      disableEnter = false,
      disableArrowUp = false,
      disableArrowDown = false,
    } = {},
  ) => {
    const isMultipleSelection = e.shiftKey;
    if (e.key === 'ArrowUp' && !disableArrowUp) {
      onFocusPreviousBlock(block, node, isMultipleSelection);
      e.preventDefault();
    }
    if (e.key === 'ArrowDown' && !disableArrowDown) {
      onFocusNextBlock(block, node, isMultipleSelection);
      e.preventDefault();
    }
    if (e.key === 'Enter' && !disableEnter) {
      if (!disableAddBlockOnEnterKey) {
        onSelectBlock(onAddBlock(config.settings.defaultBlockType, index + 1));
      }
      e.preventDefault();
    }
  };

  const onFocusPreviousBlock = (
    currentBlock,
    blockNode,
    isMultipleSelection,
  ) => {
    const prev = previousBlockId(properties, currentBlock);
    if (prev === null) return;

    blockNode.blur();

    onSelectBlock(prev, isMultipleSelection);
  };

  const onFocusNextBlock = (currentBlock, blockNode, isMultipleSelection) => {
    const next = nextBlockId(properties, currentBlock);
    if (next === null) return;

    blockNode.blur();

    onSelectBlock(next, isMultipleSelection);
  };

  const onMutateBlock = (id, value) => {
    const newFormData = mutateBlock(properties, id, value);
    onChangeFormData(newFormData);
  };

  const onInsertBlock = (id, value, current) => {
    const [newId, newFormData] = insertBlock(
      properties,
      id,
      value,
      current,
      config.experimental.addBlockButton.enabled ? 1 : 0,
    );

    const blocksFieldname = getBlocksFieldname(newFormData);
    const blockData = newFormData[blocksFieldname][newId];
    newFormData[blocksFieldname][newId] = applyBlockDefaults({
      data: blockData,
      intl,
      metadata,
      properties,
    });

    onChangeFormData(newFormData);
    return newId;
  };

  const onAddBlock = (type, index) => {
    if (editable) {
      const [id, newFormData] = addBlock(properties, type, index);
      const blocksFieldname = getBlocksFieldname(newFormData);
      const blockData = newFormData[blocksFieldname][id];
      newFormData[blocksFieldname][id] = applyBlockDefaults({
        data: blockData,
        intl,
        metadata,
        properties,
      });
      onChangeFormData(newFormData);
      return id;
    }
  };

  const onChangeBlock = (id, value) => {
    const newFormData = changeBlock(properties, id, value);
    onChangeFormData(newFormData);
  };

  const onDeleteBlock = (id, selectPrev) => {
    const previous = previousBlockId(properties, id);

    const newFormData = deleteBlock(properties, id);
    onChangeFormData(newFormData);

    onSelectBlock(selectPrev ? previous : null);
  };

  const onMoveBlock = (dragIndex, hoverIndex) => {
    const newFormData = moveBlock(properties, dragIndex, hoverIndex);
    onChangeFormData(newFormData);
  };

  const onSwapBlocks = (first, second) => {
    const blocksLayoutFieldname = getBlocksLayoutFieldname(properties);
    const blocksLayout = properties[blocksLayoutFieldname].items;
    const newBlocksLayout = [...blocksLayout];
    const temp = newBlocksLayout[blocksLayout.indexOf(first)];
    newBlocksLayout[blocksLayout.indexOf(first)] =
      newBlocksLayout[blocksLayout.indexOf(second)];
    newBlocksLayout[blocksLayout.indexOf(second)] = temp;
    const newFormData = {
      ...properties,
      [blocksLayoutFieldname]: {
        ...properties[blocksLayoutFieldname],
        items: newBlocksLayout,
      },
    };
    onChangeFormData(newFormData);
  };

  // const defaultBlockWrapper = ({ draginfo }, editBlock, blockProps) => (
  //   <EditBlockWrapper draginfo={draginfo} blockProps={blockProps}>
  //     {editBlock}
  //   </EditBlockWrapper>
  // );

  // const editBlockWrapper = children || defaultBlockWrapper;

  // Remove invalid blocks on saving
  // Note they are alreaady filtered by DragDropList, but we also want them
  // to be removed when the user saves the page next. Otherwise the invalid
  // blocks would linger for ever.
  for (const [n, v] of blockList) {
    if (!v) {
      const newFormData = deleteBlock(properties, n);
      onChangeFormData(newFormData);
    }
  }

  useEvent('voltoClickBelowContent', () => {
    if (!config.experimental.addBlockButton.enabled || !isMainForm) return;
    onSelectBlock(
      onAddBlock(config.settings.defaultBlockType, blockList.length),
    );
  });

  function handleDragEnd(event) {
    const active = event.active.id;
    const over = event.over.id;
    onSwapBlocks(active, over);
  }

  const sensors = useSensors(
    useSensor(GridCellPointerSensor, {
      // Require the mouse to move by 1 pixels before activating
      activationConstraint: {
        distance: 1,
      },
    }),
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // }),
  );

  return (
    <div
      className={cx('blocks-form', variation)}
      role="presentation"
      ref={ref}
      onKeyDown={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
      }}
    >
      <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
        {blockList.map(([childId, child], index) => {
          const blockProps = {
            allowedBlocks,
            showRestricted,
            block: childId,
            data: child,
            handleKeyDown,
            id: childId,
            formTitle: title,
            formDescription: description,
            index,
            manage,
            onAddBlock,
            onInsertBlock,
            onChangeBlock,
            onChangeField,
            onChangeFormData,
            onDeleteBlock,
            onFocusNextBlock,
            onFocusPreviousBlock,
            onMoveBlock,
            onMutateBlock,
            onSelectBlock,
            pathname,
            metadata,
            properties,
            contentType: type,
            navRoot,
            blocksConfig,
            selected: selectedBlock === childId,
            multiSelected: multiSelected?.includes(childId),
            type: child['@type'],
            editable,
            showBlockChooser: selectedBlock === childId,
            detached: isContainer,
            // Properties to pass to the BlocksForm to match the View ones
            content: properties,
            history,
            location,
            token,
          };

          return (
            <EditBlockWrapper key={childId} blockProps={blockProps}>
              <DroppableWrapper childId={childId} blockProps={blockProps} />
            </EditBlockWrapper>
          );
        })}
      </DndContext>
    </div>
  );
};

export default BlocksForm;
