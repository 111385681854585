import { useState } from 'react';
import ContainerEdit from './ContainerEdit';
import type { BlockEditProps } from '@plone/types';
import { withBlockExtensions } from '@plone/volto/helpers';

declare module 'react' {
  export interface MouseEvent {
    block: string;
  }
}

const GridBlockEdit = (props: BlockEditProps) => {
  const [selectedBlock, setSelectedBlock] = useState<string | null>(null);

  return (
    <div
      // This is required to enabling a small "in-between" clickable area
      // for bringing the Grid sidebar alive once you have selected an inner block
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        if (!e.block) setSelectedBlock(null);
      }}
      role="presentation"
    >
      <ContainerEdit
        {...props}
        selectedBlock={selectedBlock}
        setSelectedBlock={setSelectedBlock}
      />
    </div>
  );
};

export default withBlockExtensions(GridBlockEdit);
