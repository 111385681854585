import React from 'react';
import { useIntl } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ContactSchema } from './schema';

const ContactData = (props) => {
  const { block, blocksConfig, data, onChangeBlock } = props;
  const intl = useIntl();
  const schema = ContactSchema({ ...props, intl });

  const handleChange = React.useCallback(
    async (id, image, { title, image_field, image_scales } = {}) => {
      const url = image ? image['@id'] || image : '';

      onChangeBlock(block, {
        ...data,
        url: flattenToAppURL(url),
        image_field,
        image_scales,
        alt: data.alt || title || '',
      });
    },
    [block, data, onChangeBlock],
  );

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={handleChange}
      formData={data}
      block={block}
      onChangeBlock={onChangeBlock}
      blocksConfig={blocksConfig}
    />
  );
};

export default ContactData;
