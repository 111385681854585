import type { BlockViewProps } from '@plone/types';
import { RenderBlocks } from '@plone/volto/components';
import cx from 'classnames';

const Grid2Standard = (props: BlockViewProps) => {
  const { data, path, className, blocksConfig } = props;
  const metadata = props.metadata || props.properties;
  const location = {
    pathname: path,
  };
  return (
    <div className={cx('block masonry', className, data.variation)}>
      <RenderBlocks
        {...props}
        metadata={metadata}
        content={data}
        location={location}
        blocksConfig={blocksConfig}
        isContainer
      />
    </div>
  );
};

export default Grid2Standard;
