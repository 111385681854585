import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'title',
    defaultMessage: 'Title',
  },
  image: {
    id: 'image',
    defaultMessage: 'Image',
  },
});

export function ContactSchema(props) {
  const { intl } = props;
  let schema = {
    title: props.intl.formatMessage(messages.title),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['url'],
      },
    ],
    properties: {
      url: {
        title: intl.formatMessage(messages.image),
        widget: 'image',
      },
    },
    required: [],
  };

  return schema;
}
