import React from 'react';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { setSidebarTab, setMetadataFocus } from '@plone/volto/actions';
import { Container } from '@plone/components';
import { UniversalLink, Icon } from '@plone/volto/components';
import aheadSVG from '@plone/volto/icons/ahead.svg';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import cx from 'classnames';
import { getCurrentStyleByName } from '../../helpers';

function groupByBGColor(blocks, blocks_layout, colorDefinitions) {
  const result = [];
  let currentArr = [];
  let currentBGColor;

  blocks_layout.items.forEach((blockId) => {
    let currentBlockColor =
      getCurrentStyleByName(
        colorDefinitions,
        'backgroundColor:noprefix',
        blocks[blockId],
      ) || 'transparent';
    if (currentBlockColor !== currentBGColor) {
      currentBGColor = currentBlockColor;
      // write it only if the array has some block inside
      if (currentArr.length > 0) {
        result.push(currentArr);
        currentArr = [];
      }
    }

    currentArr.push(blockId);
  });
  result.push(currentArr);
  return result;
}

const LandingPageHeading = () => {
  const dispatch = useDispatch();
  const content = useSelector((state) => state.content.data);
  const formState = useSelector((state) => state.form.global);

  const backgroundColor =
    formState?.background_color || content?.background_color;

  const websiteLink = formState?.website || content?.website;

  const defaultColorStyle = config.blocks.themes.find(
    (color) => color.name === config.settings.defaultBackgroundColor,
  )?.style;

  const style =
    typeof backgroundColor === 'object' && !isEmpty(backgroundColor)
      ? backgroundColor
      : defaultColorStyle;

  // Find out the first block background color
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const grouped = groupByBGColor(
    content[blocksFieldname],
    content[blocksLayoutFieldname],
    config.blocks.themes,
  );
  const firstBlockBGColor =
    content[blocksFieldname][grouped[0][0]]?.['styles']?.[
      'backgroundColor:noprefix'
    ]?.['--background-color'];

  return (
    <Container
      onClick={
        !isEmpty(formState)
          ? () => {
              dispatch(setSidebarTab(0));
              dispatch(setMetadataFocus('default', 'title'));
            }
          : null
      }
    >
      <div
        className={cx('landing-page-heading slot-outer-container', {
          nopadding:
            firstBlockBGColor === backgroundColor?.['--background-color'],
        })}
        style={style}
      >
        <div className="slot-inner-container">
          <h1>
            {(() => {
              const value = formState?.title || content?.title;
              // We detect the `\n` appearences, then we rewrite the values as JSX
              // inserting a <br/>
              const values = value.split('\\n');
              return values
                .reduce(
                  (res, item, index) =>
                    res.concat(
                      <React.Fragment key={index}>{item}</React.Fragment>,
                      <React.Fragment key={'br'}>
                        <br />
                      </React.Fragment>,
                    ),
                  [],
                )
                .slice(0, -1);
            })()}
          </h1>
          {(formState?.description || content?.description) && (
            <p
              // We add the nopadding class to the p tag if the first block has the same background color as the heading
              className={cx({
                nopadding:
                  firstBlockBGColor === backgroundColor?.['--background-color'],
              })}
            >
              {formState?.description || content?.description}
            </p>
          )}
          {websiteLink && (
            <div>
              <UniversalLink href={websiteLink}>
                <Icon size={20} name={aheadSVG} />
                <span>{websiteLink}</span>
              </UniversalLink>{' '}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default LandingPageHeading;
