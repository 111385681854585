import { useState } from 'react';
// import { useIntl } from 'react-intl';
import { isEmpty, pickBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import { SidebarPortal } from '@plone/volto/components';
import BlocksForm from './BlocksForm';
import ContainerData from '@plone/volto/components/manage/Blocks/Container/Data';
// import DefaultEditBlockWrapper from '@plone/volto/components/manage/Blocks/Container/EditBlockWrapper';
import SimpleContainerToolbar from '@plone/volto/components/manage/Blocks/Container/SimpleContainerToolbar';
import { blocksFormGenerator } from '@plone/volto/helpers';

import DefaultPresetChooser from './PresetChooser';

import config from '@plone/volto/registry';
import type { BlockEditProps, BlocksData } from '@plone/types';

type ContainerBlockEditProps = BlockEditProps & {
  setSelectedBlock?: (id: string) => void;
  selectedBlock?: string | null;
};

declare module '@plone/types' {
  export interface BlocksFormData {
    blocks: Record<string, any>;
    blocks_layout: {
      items: string[];
    };
  }
}

const ContainerBlockEdit = (props: ContainerBlockEditProps) => {
  const {
    block,
    data,
    onChangeBlock,
    onChangeField,
    pathname,
    selected,
    manage,
    variation,
  } = props;

  // const intl = useIntl();
  const blockType = data['@type'];
  const metadata = props.metadata || props.properties;
  const isInitialized = data?.blocks && data?.blocks_layout;
  const properties = isInitialized ? data : blocksFormGenerator(0, '');
  const blockConfig = config.blocks.blocksConfig[blockType];
  const blocksConfig = blockConfig.blocksConfig || props.blocksConfig;
  const allowedBlocks = blockConfig.allowedBlocks || [];
  const presets = blockConfig.presets || [];
  const maxLength = variation?.maxLength || 3;
  const ContainerToolbar =
    blockConfig.containerToolbar || SimpleContainerToolbar;

  // Custom components from config or default ones
  const PresetChooser = blockConfig.presetComponent || DefaultPresetChooser;
  // const EditBlockWrapper =
  //   blockConfig.editBlockWrapper || DefaultEditBlockWrapper;

  let [selectedBlock, setSelectedBlock] = useState(
    properties.blocks_layout.items[0],
  );
  if (props.setSelectedBlock) {
    ({ selectedBlock, setSelectedBlock } = props);
  }

  const blockState: any = {};

  const onSelectPreset = (presetId: string) => {
    const index = presets.findIndex((preset) => preset.id === presetId);
    onChangeBlock(block, {
      ...data,
      ...presets[index].data,
    });
  };

  const allowedBlocksConfig = pickBy(blocksConfig, (value, key) =>
    allowedBlocks.includes(key),
  );

  const onAddNewBlock = () => {
    const newuuid = uuid();
    const type = allowedBlocks?.length === 1 ? allowedBlocks[0] : null;
    const blocks = data.blocks || properties.blocks;
    const blocks_layout = data.blocks_layout || properties.blocks_layout;
    const newFormData = {
      ...data,
      blocks: {
        ...blocks,
        [newuuid]: { '@type': type || 'empty' },
      },
      blocks_layout: {
        items: [...blocks_layout.items, newuuid],
      },
    };
    if (blocks_layout.items.length < maxLength) {
      onChangeBlock(block, {
        ...newFormData,
      });
    }
  };

  const containerProps = {
    ...props,
    allowedBlocks,
    allowedBlocksConfig,
    blocksConfig,
    blockType,
    metadata,
    onSelectPreset,
    selectedBlock,
    setSelectedBlock,
    onAddNewBlock,
    maxLength,
  };

  return (
    <>
      {data.headline && <h2 className="headline">{data.headline}</h2>}
      {selected && <ContainerToolbar {...containerProps} />}
      {!isInitialized && !isEmpty(presets) && (
        <PresetChooser presets={presets} onSelectPreset={onSelectPreset} />
      )}

      <BlocksForm
        metadata={metadata}
        properties={properties}
        manage={manage}
        selectedBlock={selected ? selectedBlock : null}
        blocksConfig={allowedBlocksConfig}
        title={data.placeholder}
        isContainer
        stopPropagation={selectedBlock}
        disableAddBlockOnEnterKey
        onSelectBlock={(id: string) => {
          setSelectedBlock(id);
        }}
        onChangeFormData={(newFormData: BlocksData) => {
          onChangeBlock(block, {
            ...data,
            ...newFormData,
          });
        }}
        onChangeField={(id: string, value: any) => {
          if (['blocks', 'blocks_layout'].includes(id)) {
            blockState[id] = value;
            onChangeBlock(block, {
              ...data,
              ...blockState,
            });
          } else {
            onChangeField(id, value);
          }
        }}
        pathname={pathname}
        variation={variation?.id || data.variation}
      />
      <SidebarPortal selected={selected && !selectedBlock}>
        <ContainerData {...props}></ContainerData>
      </SidebarPortal>
    </>
  );
};

export default ContainerBlockEdit;
