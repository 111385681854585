import type { BlockViewProps } from '@plone/types';
import { withBlockExtensions } from '@plone/volto/helpers';
import config from '@plone/registry';

type BlockBodyProps = {
  defaultBody: React.ComponentType<any>;
  bodyComponentName?: string;
  bodyComponentDependencies?: string[];
};

const BlockBody = (props: BlockBodyProps & BlockViewProps) => {
  const { bodyComponentName, bodyComponentDependencies, defaultBody, ...rest } =
    props;
  if (bodyComponentName) {
    const BodyComponent = (config.getComponent({
      name: bodyComponentName,
      dependencies: bodyComponentDependencies,
    }).component ||
      props.variation?.render ||
      defaultBody) as React.ComponentType<any>;

    return <BodyComponent {...rest} />;
  }

  const BodyComponent = props.variation?.render || defaultBody;
  return <BodyComponent {...rest} />;
};

const MasonryBlockView = (props: BlockViewProps) => {
  return <BlockBody {...props} defaultBody={() => <div>default</div>} />;
};

export default withBlockExtensions(MasonryBlockView);
