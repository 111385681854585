import { useIntl } from 'react-intl';
import { Button, Grid, Message } from 'semantic-ui-react';
import type { Preset } from '../../../index';

type PresetChooserProps = {
  presets: Array<Preset>;
  onSelectPreset: (preset: string) => void;
};

const PresetChooser = ({ presets, onSelectPreset }: PresetChooserProps) => {
  const intl = useIntl();
  return (
    <div className="template-chooser">
      <Message>
        {/* @ts-ignore Ignore type error */}
        <Grid columns={presets.length}>
          {presets.map((preset, index) => (
            <Grid.Column key={preset.id}>
              <Button
                className="template-chooser-item"
                onClick={() => onSelectPreset(preset.id)}
              >
                <img src={preset.image} alt="" />
                <div className="template-chooser-title">
                  {intl.formatMessage({
                    id: preset.id,
                    defaultMessage: preset.title,
                  })}
                </div>
              </Button>
            </Grid.Column>
          ))}
        </Grid>
      </Message>
    </div>
  );
};

export default PresetChooser;
